import React, {Dispatch, MutableRefObject, SetStateAction} from "react";
import {useLocation} from "@reach/router";

interface AccordionContextProps {
  allowMultiple: Boolean;
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
}

let defaultValue: AccordionContextProps = {
  allowMultiple: false,
  selected: null,
  setSelected: () => {},
};

const AccordionContext =
  React.createContext<AccordionContextProps>(defaultValue);

interface AccordionItemProps {
  title: string;
  id?: string;
}
const AccordionItemComponent: React.FC<AccordionItemProps> = ({
  title,
  children,
  id,
}) => {
  const [open, setOpen] = React.useState<Boolean>(false);
  const context = React.useContext(AccordionContext);
  const ref = React.useRef<HTMLDivElement>(null);
  let isSelected = context.selected == title || (context.allowMultiple && open);
  let height = isSelected ? ref.current.scrollHeight : 0;
  const location = useLocation();

  const handleClick = () => {
    if (context.allowMultiple) {
      setOpen(!open);
    }

    if (isSelected) {
      context.setSelected(null);
    } else {
      context.setSelected(title);
    }
  };

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    let value = params.get("open-id");
    if (value && value == id) {
      handleClick();
    }
  }, [location]);

  return (
    <div className="accordion__item" id={id}>
      <div className="accordion__item__title" onClick={handleClick}>
        {title}
        <div>&#129171;</div>
      </div>
      <div
        ref={ref}
        style={{maxHeight: `${height}px`}}
        className={`accordion__item__content ${isSelected && "selected"}`}
      >
        {children}
      </div>
    </div>
  );
};

interface AccordionProps {
  children: any;
  allowMultiple?: Boolean;
}
const Accordion = (props: AccordionProps) => {
  let allowMultiple = props.allowMultiple ?? false;

  let [selected, setSelected] = React.useState<string>("");

  const value = {allowMultiple, selected, setSelected};
  return (
    <AccordionContext.Provider value={value}>
      <div className="accordion__container">{props.children}</div>
    </AccordionContext.Provider>
  );
};

Accordion.Item = AccordionItemComponent;
export default Accordion;
