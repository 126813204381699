import React from "react";
import Link from "./link";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";
interface props {}
const ContactButton: React.FC<props> = (props) => {
  return (
    <AnimateUp>
      <Link to="/contact/#contact-form" className="button button-secondary">
        {props.children}
      </Link>
    </AnimateUp>
  );
};
export default ContactButton;
