import React from "react";
import useInView from "../Hooks/useInView";
interface props {
  singleTime?: boolean;
  delay?: boolean;
}
const AnimateUp: React.FC<props> = (props) => {
  let singleTime = props.singleTime ?? true;
  let delay = props.delay ?? true;

  const [ref, isVisible] = useInView({
    rootMargin: "10% 0% 10% 0%",
    threshold: 1,
    unobserveOnEnter: singleTime,
  });

  let className = "";

  if (delay) className += " delay-long";
  if (isVisible) className += " animate__fadeInUp";

  return (
    <div ref={ref} className={className}>
      {isVisible && props.children}
    </div>
  );
};
export default AnimateUp;
