import React from "react";
import Row from "../../../../StarkeyDigital/StarkeyDigital.Components/React/Components/row";
import ScrollAnimation from "react-animate-on-scroll";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";
interface props {
  style?: React.CSSProperties;
  column: string;
  columnText?: string;
  header: string;
  text?: string;
}
const HeaderDivideTextLeft: React.FC<props> = (props) => {
  let columnText = props.columnText ?? props.column;
  return (
    <>
      <Row style={props.style}>
        <div className={`${props.column}`}>
          <AnimateUp>
            <h1
              style={{textAlign: "left"}}
              dangerouslySetInnerHTML={{__html: props.header}}
            />
          </AnimateUp>
        </div>
      </Row>

      <Row>
        <div className={`${columnText}`} style={{marginTop: "35px"}}>
          <AnimateUp>
            <div className="divider" />
          </AnimateUp>

          {props.text && (
            <AnimateUp>
              <p
                style={{marginTop: "35px", textAlign: "left"}}
                dangerouslySetInnerHTML={{__html: props.text}}
              />
            </AnimateUp>
          )}
        </div>
      </Row>
    </>
  );
};
export default HeaderDivideTextLeft;
