import React from "react";
import ContactButton from "../../components/contactButton";
import HeaderDivideTextLeft from "../../components/headerDivideTextLeft";
import Layout from "../../components/layout";
import Row from "starkeydigital/React/Components/row";
import Accordion from "starkeydigital/React/Components/accordion";
import ScrollAnimation from "react-animate-on-scroll";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";
interface props {}
const TeamCoaching: React.FC<props> = (props) => {
  return (
    <Layout
      titleText="Team Coaching"
      titleText2=""
      slogan="We work regularly with groups on our leadership programmes coaching and facilitating the
      discussion of key topics and agenda items."
    >
      <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col-md-10"
        header="Team Coaching"
        text="We have worked with international teams and facilitate team workshops, board meetings and group-based development activities.
        We have delivered benefits for both the organization and the individual from establishing closely networked groups of senior professionals and executives who work together on the issues they face in the workplace."
      />

      <Row>
        <div className="col mt-4">
          <ContactButton>Ready to work together?</ContactButton>
        </div>
      </Row>

      <Row style={{marginTop: "60px"}}>
        <div className="col">
          <Accordion>
            <AnimateUp>
              <Accordion.Item title="Why would you invest in Team Coaching?">
                The team is operating as less than the sum of its parts / There
                is tension in the team / The team finds it hard to work towards
                its goals
              </Accordion.Item>
            </AnimateUp>

            <AnimateUp>
              <Accordion.Item title="When would Team Coaching work for you?">
                When a team is first working together - setting out the patterns
                from the start / When a team is stuck and not working well /
                When a key individual within a team is seen as being troublesome
                / When the composition of an established team is changing
              </Accordion.Item>
            </AnimateUp>

            <AnimateUp>
              <Accordion.Item title="How would Team Coaching work?">
                We facilitate board and team meetings to allow the leader to
                participate in the discussions and decision-making process and
                to ensure that the meeting operates inclusively, and achieves
                its goals in a time efficient manner.
                <br />
                <br />
                In a team meeting environment, we observe and feedback on the
                way the existing team are interacting and then work with them to
                find new and more productive ways of working together towards
                the goal. The use of psychometric tools such as Myers Briggs may
                also give a team a constructive language to discuss differences.
              </Accordion.Item>
            </AnimateUp>
          </Accordion>
        </div>
      </Row>
    </Layout>
  );
};
export default TeamCoaching;
